export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const resolveAvailabilityMsg = function (product, type= 'available') {
    if (process.env.REACT_APP_SHOW_STOCK === "0") {
        return '';
    }

    let cases_stock = type === 'available' ? product.StockCases : product.StockOnOrderCases;
    let bottles_stock = type === 'available' ? product.StockBottles : product.StockOnOrderBottles;

    // Hide 'on order' stock if it both zero or show 'Currently Out of Stock'.
    if (cases_stock < 1 && bottles_stock < 1) {
        return type === 'available' ? 'Currently Out of Stock' : '';
    }

    let cases_ending = cases_stock > 1 ? 'cases' : 'case';
    let cases_part = cases_stock > 0 && (process.env.REACT_APP_UNIT_FLAG !== "1" || product.CaseSize === 1) ?
      `${cases_stock} ${product.CustomLabel ?? cases_ending}` :
      '';

    let bottles_ending = bottles_stock > 1 ? 'bottles' : 'bottle';
    let bottles_part = bottles_stock > 0 && ((process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && product.OnlySellMultiplesOf <= 1 && process.env.REACT_APP_UNIT_FLAG !== "2") || (!process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && process.env.REACT_APP_UNIT_FLAG !== "2")) && product.CaseSize > 1  ?
      `${bottles_stock} ${bottles_ending}` :
      '';

    // Don't display 'available' word if cases and blocks are empty.
    if (cases_part.length < 1 && bottles_part.length < 1) {
        return 'Currently Out of Stock';
    }

    // When both are filled, add 'and'.
    if (cases_part.length > 1 && bottles_part.length > 1) {
        cases_part = cases_part + ' and ';
    }

    return `${cases_part}${bottles_part} ${type}`;
}